import React from "react"
import { getList } from "./api"
import * as css from "./style.module.css"

const styleBorder = { border: "1px solid black" }

const ListOfRegistered = () => {
  const [list, setList] = React.useState([])

  React.useEffect(() => {
    getList().then(_list => {
      setList(_list)
      // console.log({ list: _list })
    })
  }, [])

  return (
    <>
      <div>Перелік зареєстрованих</div>
      <table>
        <thead>
          <th style={styleBorder}>Дата</th>
          <th style={styleBorder}>Імя</th>
          <th style={styleBorder}>email</th>
          <th style={styleBorder}>phone</th>
        </thead>
        <tbody>
          {list.map(doc => (
            <tr key={doc.id}>
              <td style={styleBorder}>{Date(doc.timestamp).toString()}</td>
              <td style={styleBorder}>{doc.name}</td>
              <td style={styleBorder}>{doc.email}</td>
              <td style={styleBorder}>{doc.phone}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default ListOfRegistered
