import fb from "firebase/app"
import getFirebase from "../../firebase"

export function getList() {
  const firebase = getFirebase()
  return firebase.firestore
    .collection("registrations")
    .get()
    .then(snapshot => {
      const dataArr = []
      snapshot.forEach(doc => {
        dataArr.push({
          id: doc.id,
          ...doc.data(),
        })
      })
      return dataArr
      // snapshot.forEach(doc => {
      //   console.log(doc.id, " => ", doc.data())
      // })
    })
}
